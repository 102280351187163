.vaccinations {
  background: url("../../images/vaccionation-bg.png") center center no-repeat;
  background-size: cover;
  max-width: none;
  margin-left: 0;
  margin-right: 0;

  &> * {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
  }

  .card {
    border-radius: 0;
    background-color: transparent;
    border-left: 0;
    border-right: none;
    border-color: $vaccinations-item-header-border;

    &:first-of-type {
      border-top: 0;
    }

    &:last-of-type {
      border-bottom: 0;

      .card-header {
        border-bottom: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      border: none;
    }

    .card-header {
      background-color: transparent;
      border-color: $vaccinations-item-header-border;

      a {
        color: $vaccinations-item-header-color;
        text-decoration: none;
        font-weight: 500;

        &[aria-expanded=true],
        &:focus,
        &:hover {
          background: $vaccinations-item-header-active-bg;
          color: $vaccinations-item-header-active-color;
          font-weight: 900;
          i  {
            display: none;
          }
        }

        i {
          color: $vaccinations-item-header-arrow-right;
        }
      }

      @include media-breakpoint-up(lg) {
        display: none;
        font-size: 1.25em;
      }
    }

    .card-body {
      color: $vaccinations-item-content-color;
    }

    .collapse {
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  .tab-pane {
    @include media-breakpoint-down(md) {
      display: block !important;
      opacity: 1;
    }
  }

  .accordion {
    background: $pagepart-vaccinations-bg;
    border-radius: 5px;

    @include media-breakpoint-up(lg) {
      background: $pagepart-vaccinations-desktop-bt;
    }
  }

  .nav-tabs {
    width: 580px;
    border: 0;
    background: $pagepart-vaccinations-bg;

    .nav-item {
      border-bottom: 1px solid $vaccinations-item-header-border;

      .nav-link {
        padding: 13px 16px;
        border-radius: 0;
        border: none;
        background: transparent;
        color: $vaccinations-item-header-color;
        font-weight: 500;
        position: relative;

        &.active,
        &:hover,
        &:focus {
          background: $vaccinations-item-header-active-bg;
          color: $vaccinations-item-header-active-color;
          font-weight: 900;

          i  {
            visibility: hidden;
          }

          span.arrow-right {
            position: absolute;
            left: 100%;
            height: 50px;

            &::after {
              content: "";
              display: block;
              position: absolute;
              width: 0;
              height: 0;
              border-top: 25px solid transparent;
              border-left: 15px solid white;
              border-bottom: 25px solid transparent;
              left: 100%;
              top: 0;
            }
          }
        }

        i {
          color: $vaccinations-item-header-arrow-right;
          padding-right: 20px;
        }
      }
    }
  }
}
