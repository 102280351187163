.how-it-works {
  max-width: none;
  background: $how-it-works-bg;
  margin-left :0;
  margin-right: 0;

  &> * {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
  }

  .card {
    margin-top: 70px;

    @include media-breakpoint-up(sm) {
      margin-top: 50px;
    }

    .card-img-top {
      margin-top: -50px;

      i {
        width: 179px;
        height: 169px;
        display: block;
        font-size: 100px;
        line-height: 1.5;
        background: url("../../images/how-it-works.png") center center no-repeat;
      }
    }

    .card-title {
      color: $how-it-works-title-color;
      font-size: 1.5em;
      font-weight: 700;
    }

    .card-text {
      color: $how-it-works-content-color;
      font-size: 1.25em;
    }
  }
}
