.page-content {
  .vaccination-item {
    padding-bottom: 25px;

    & + .vaccination-item {
      border-top: 5px solid $vaccinations-list-separator-color;
      padding-top: 50px;
    }

    .child-name {
      font-weight: 600;
      color: $vaccinations-list-child-color;
      font-size: 2rem;

      small {
        color: $vaccinations-list-bdate-color;
        font-size: 0.5625em;
        font-weight: 700;
      }
    }

    .card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .card-text {
      font-size: 1.25rem;

      a {
        text-decoration: underline;
      }
    }
  }


}
