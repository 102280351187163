.news-list {
  .card-title a {
    color: $news-teaser-title-color;
    font-weight: 700;
    font-size: 1.5rem;
    text-decoration: none;
  }

  .card-text {
    color: $news-teaser-content-color;
    font-size: 1.125em;
  }

  .card-img-top {
    height: 210px;
    width: auto;
    object-fit: cover;
  }
}
