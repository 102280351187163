.news-list {
  .media {
    margin-bottom: 20px;
    img {
      height: auto;
      width: 100%;
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        width: 200px;
        height: auto;
      }
    }

    .card-title {
      font-size: 0.75em;
    }
  }
}