@mixin buttons($bg-color, $border-color, $font-color) {
    background: $bg-color;

    border: 2px solid $border-color;
    border-radius: 5px;

    color: $font-color;
    font-weight: 500;
}

html,
body {
    height: 100%;
}

main {
    flex: 1 0 auto;

    .page-content {
        ul {
            list-style: none;
            padding-left: 15px;
            li::before {
                content: "\2022";
                color: $list-bullet-color;
                font-weight: 700;
                display: inline-block;
                width: .75em;
                margin-left: -.75em;
            }
        }
    }
}

.underlined-text {
    text-decoration: underline;
}

.italic-text {
    font-style: italic;
}

