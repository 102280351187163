$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../../fonts" !default;

$icon-injection: "\e90a";
$icon-info: "\e909";
$icon-garbage: "\e908";
$icon-speech-bubble: "\e905";
$icon-plus: "\e906";
$icon-avatar: "\e907";
$icon-webtown: "\e904";
$icon-arrow-down: "\e903";
$icon-login: "\e901";
$icon-password: "\e902";
$icon-user: "\e900";
$icon-linkedin2: "\eaca";
$icon-facebook: "\ea90";
$icon-arrow-right2: "\ea3c";

$font-family-sans-serif: 'Montserrat', sans-serif;

$navbar-dark-toggler-border-color: transparent;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgb(255, 255, 255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-icon-hover-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8l20 16M4 24L24 8'/%3E%3C/svg%3E");
$btn-focus-box-shadow: none;
$link-color: black;
$link-hover-color: #ED6EA7;
$body-color: #3C4851;
$form-feedback-invalid-color: red;
$form-delete-collection-color: red;
$list-bullet-color: #ED6EA7;


$white: #FFFFFF;
$black: #000000;

$max-width: 1280px;

/*
 * Cookie consent
 */
$blue:                  #9cb2be;
$brand-primary:         $blue;
$brand-primary--dark:   darken($brand-primary, 15%);

/*
 * Button colors
 */
$blue-button-bg: #B3BFC9;
$blue-button-border: #B3BFC9;
$blue-button-color: #3A464F;
$pink-button-bg: #F089B8;
$pink-button-border: #F089B8;
$pink-button-color: $white;
$vacc-button-bg: $white;
$vacc-button-border: #B3BFC9;
$vacc-button-color: #3A464F;
$vacc-button-hover-color: #ED6EA7;
$vacc-button-hover-border: #F089B8;
$fb-button-bg: #3B5998;
$fb-button-border: #3B5998;
$fb-button-hover-bg: #213C98;
$fb-button-hover-border: #213C98;
$fb-button-color: white;

/*
 * Header block colors
 */
$header-navbar-bg: #3A5969;
$header-navbar-shadow: #2D4A59;
$header-navbar-active: #ED6EA7;
$header-navbar-dropdown-bg: #8B9FAA;
$header-navbar-dropdown-color: $white;
$header-navbar-dropdown-border: #B3C0C8;
$header-navbar-mobile-dropdown-bg: #224050;
$header-navbar-mobile-dropdown-color: #BECDD8;
$header-navbar-color: #BECED8;
$header-nav-item-border: #4C6E7F;
$header-user-bg: #476778;
$header-button-icon: #6F8B99;
$header-button-icon-hover: #ED6EA7;
$header-dropdown-btn-bg: #ED6EA7;
$header-dropdown-border: #F3A0C6;
$header-dropdown-hover-color: #3A464F;

/*
 * Footer block colors
 */
$footer-main-bg: #3A5969;
$footer-main-shadow: #2D4A59;
$footer-sub-bg: #2D353D;
$footer-color: $white;
$footer-social-bg: #92ABB8;
$footer-social-color: #3A5969;
$footer-border-color: #4D6D7E;

/*
 * Hero block colors
 */
$hero-bg-color: #DCDFE0;
$hero-h3-color: #ED6EA7;
$hero-h2-color: #4F6F80;
$hero-p-color: #3C4851;

/*
 * Content block colors
 */
$pagepart-lightbg-title-color: #4F6F80;
$pagepart-lightbg-content-color: #3C4851;
$pagepart-darkbg-title-color: $white;
$pagepart-header-box-bg: #ED6EA7;

/*
 * Vaccines block colors
 */
$pagepart-vaccinations-bg: rgba(255, 255, 255, 0.03);
$pagepart-vaccinations-desktop-bt: rgba(255, 255, 255, 0.15);
$vaccinations-item-header-color: #7CC1E3;
$vaccinations-item-header-active-bg: $white;
$vaccinations-item-header-active-color: #ED6EA7;
$vaccinations-item-header-arrow-right: #ED6EA7;
$vaccinations-item-header-active-arrow-right: $white;
$vaccinations-item-content-color: $white;
$vaccinations-item-header-border: #4E646D;

/*
 * How it works block colors
 */
$how-it-works-bg: #EBEBEB;
$how-it-works-title-color: #ED6EA7;
$how-it-works-content-color: #3C4851;

/*
 * News teaser block colors
 */
$news-teaser-title-color: #ED6EA7;
$news-teaser-content-color: #3C4851;

$pagetitle-block-border-color: #EBEBEB;
$pagetitle-icon-color: #cdcdcd;

/*
 * Login background
 */
$login-form-bg: #EBEBEB;

$flash-error-bg: red;
$flash-text-color: white;

/*
 * Form colors
 */
$form-helper-icon: #EE77AD;

/*
 * Vaccination list colors
 */
$vaccinations-list-child-color: #ED6EA7;
$vaccinations-list-bdate-color: #8B9FAA;
$vaccinations-list-separator-color: #EBEBEB;
