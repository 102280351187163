.pagepart {
  padding: 25px 1rem 80px;
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;

  h2 {
    margin-top: 60px;
    font-size: 2.25em;
    font-weight: 800;
    color: $pagepart-lightbg-title-color;
    text-align: center;
    padding-bottom: 50px;

    @include media-breakpoint-up(lg) {
      font-size: 2.75em;
    }

    &:after {
      content: "";
      display: block;
      width: 13px;
      height: 13px;
      background: $pagepart-header-box-bg;
      border-radius: 5px;
      margin: 35px auto 0;
    }
  }

  p {
    font-size: 1.125em;
  }

  &.dark {
    h2 {
      color: $pagepart-darkbg-title-color;
    }
  }
}
