.content-header {
  border: none;
  width: 100%;
  @include media-breakpoint-up(md) {
    border-bottom: 5px solid $pagetitle-block-border-color;
    border-top: 5px solid $pagetitle-block-border-color;
  }
}
h2.content-title {
  height: auto;
  max-width: 1310px;
  text-align: left;
  color: $pagepart-lightbg-title-color;
  font-size: 2.25em;
  font-weight: 800;
  padding: 65px 15px 25px;

  @include media-breakpoint-up(md) {
    padding-top: 40px;
    padding-bottom: 0;
    height:175px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 2.75em;
  }

  i {
    color: $pagetitle-icon-color;
    font-size: 1.7777777777777777em;
  }
}

.page-content {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.25em;

  .divider {
    background-color: #ebebeb;
    line-height: 5px;
    margin: 25px 0;

    .divider-title {
      background: #fff;
      font-size: 14px;
      letter-spacing: 1px;
      padding: 0 20px;
    }
  }
}

.registration-image {
  background-image: url('../../images/hero.jpg');
  background-size: cover;
  background-position: 80% 50%;
}
