@import "../common/variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?zhfmb0') format('truetype'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?zhfmb0') format('woff'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?zhfmb0##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-injection {
  &:before {
    content: $icon-injection;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-garbage {
  &:before {
    content: $icon-garbage;
  }
}
.icon-speech-bubble {
  &:before {
    content: $icon-speech-bubble;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-avatar {
  &:before {
    content: $icon-avatar;
  }
}
.icon-webtown {
  &:before {
    content: $icon-webtown;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-login {
  &:before {
    content: $icon-login;
  }
}
.icon-password {
  &:before {
    content: $icon-password;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-linkedin2 {
  &:before {
    content: $icon-linkedin2;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-arrow-right2 {
  &:before {
    content: $icon-arrow-right2;
  }
}
