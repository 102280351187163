.btn-vacc {
    @include buttons($vacc-button-bg, $vacc-button-border, $vacc-button-color);

    &:hover,
    &:focus {
        @include buttons($vacc-button-bg, $vacc-button-hover-border, $vacc-button-hover-color);

        i {
            color: $header-button-icon-hover;
        }
    }
}

.btn-group {
    .btn-vacc {
        @include buttons($vacc-button-bg, $vacc-button-border, $vacc-button-color);

        &:hover,
        &:focus {
            @include buttons($vacc-button-bg, $vacc-button-border, $vacc-button-color);

            i {
                color: $header-button-icon;
            }
        }
    }
}

.btn-pink {
    @include buttons($pink-button-bg, $pink-button-border, $pink-button-color);

    &:hover,
    &:focus {
        @include buttons(transparent, $pink-button-border, $pink-button-bg);
    }
}

.btn-blue {
    @include buttons($fb-button-bg, $fb-button-border, $fb-button-color);

    &:hover,
    &:focus {
        @include buttons($fb-button-hover-bg, $fb-button-hover-border, $fb-button-color);
    }
}
