.hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: -1;

  @include media-breakpoint-down(md) {
    background-image: none !important;
    background-color: $hero-bg-color;
    padding-bottom: 0 !important;
  }

  @include media-breakpoint-up(lg) {
    border-bottom: 5px solid $pagetitle-block-border-color;
    border-top: 5px solid $pagetitle-block-border-color;
  }

  .hero-content {
    padding-top: 60px;
    padding-bottom: 100px;
    max-width: $max-width;

    @include media-breakpoint-up(lg) {
      padding-top: 100px;
      padding-bottom: 70px;
    }

    @include media-breakpoint-up(xl) {
      padding-top: 160px;
    }

    h2 {
      color: $hero-h2-color;
      font-weight: 800;
      font-size: 2.25em;

      @include media-breakpoint-up(sm) {
        font-size: 2.75em;
      }

      @include media-breakpoint-up(lg) {
        font-size: 2em;
        max-width: 640px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 750px;
      }
    }

    h3 {
      color: $hero-h3-color;
      font-weight: 800;
      font-size: 1.875em;

      @include media-breakpoint-up(lg) {
        max-width: 750px;
      }
    }

    p {
      color: $hero-p-color;
      font-size: 1.25em;

      @include media-breakpoint-up(lg) {
        max-width: 550px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 650px;
      }
    }
  }
}
