@import "common/variables";
@import "common/globals";

@import "./autoload/_bootstrap.scss"; @import "./autoload/style.scss";
@import "./../ui/scss/components/structures/cookie-consent";
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';
@import '~animate.css/animate.min.css';

@import "common/pagepart";

@import "component/button";
@import "component/card";
@import "component/form";

@import "layout/header";
@import "layout/content";
@import "layout/login";
@import "layout/footer";
@import "layout/news";
@import "layout/vaccination-list";

@import "pagepart/hero";
@import "pagepart/about-vacc";
@import "pagepart/vaccionations";
@import "pagepart/how-it-works";
@import "pagepart/news-list";
@import "pagepart/image";
