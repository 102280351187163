.login-form-container {
  margin-top: 0;
  margin-bottom: 20px;

  @include media-breakpoint-up(lg) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .login-error {
    background: $flash-error-bg;
    min-height: 55px;
    color: $flash-text-color;
    text-align: left;
    font-size: 18px;
    width: 100%;
    border-radius: 0;
    padding: 14px 10px;

    @include media-breakpoint-up(lg) {
      border-radius: 5px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

.login-form {
  margin-top: 0;
  background-color: $login-form-bg;
  padding: 20px 10px;
  border-radius: 0;

  @include media-breakpoint-up(lg) {
    padding: 40px 70px;
    margin-top: 15px;
    border-radius: 5px;
  }

  .col-form-label {
    padding-top: 0;
  }
}

.form-reset-password {
  display: none;
}

a.forgot-password, a.back-to-login {
  display: block;
  margin-bottom: 20px;
}

.login-buttons {
  .btn-pink,
  .btn-blue {
    img,
    i {
      margin-right: 10px;
    }
  }
}
