/* ==========================================================================
   Cookie consent
   ========================================================================== */

.cookie-bar {
    position: fixed;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    z-index: 500;

    display: none;
    width: 100%;
    padding: 20px;

    background: rgba($brand-primary--dark, .95);

    border: 1px solid rgba($brand-primary--dark, .95);

    color: $white;
    font-size: 14px;
    line-height: 1.7;
    text-align: left;

    animation-name: cookieSlide;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: .8s;

    .cookie-bar__policy {
        color: $white;
        text-decoration: underline;
    }

    .cookie-bar__btn {
        padding: 10px 15px;

        background: transparent;

        border-radius: 4px;
        border: 2px solid rgba(0, 0, 0, 0.87);

        color: rgba(0, 0, 0, 0.87);
        font-size: 1em;
        font-weight: 500;
        transition: background-color .3s ease-in-out;

        &:hover, &:focus {
            background: #5a717d;
        }
    }

    p {
        padding: 0;
        font-weight: 500;
        margin: 0;

        color: rgba(0, 0, 0, 0.87);
        font-size: 1em;
        text-align: left;

        a {
            color: #0a171d;
            text-decoration: underline;
        }
    }
}

.cookie-bar--visible {
    display: block;
}

@keyframes cookieSlide {
    0% {
        transform: translate3d(0, 60px, 0);

        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);

        opacity: 1;
    }
}

/**
* @doc docs/cookie-consent.md
* @section
* @sectionof Structures
*/
.cookie-bar {
    .cookie-content {
        max-width: 1280px;
    }
}
