.footer {
    a {
        color: $footer-color;
    }

    p {
        font-size: .875em;
        color: $footer-color;
        margin-bottom: 0;
        line-height: 1.75em;
    }
    .main-footer {
        > * {
            max-width: 1310px;
            margin-left: auto;
            margin-right: auto;
        }

        background: linear-gradient(to bottom, $header-navbar-shadow 0, $header-navbar-bg 37px, $header-navbar-bg 100%);

        h3 {
            font-weight: 600;
            font-size: .875em;
            color: $footer-color;
        }

        .footer-menu {
            @include media-breakpoint-up(md) {
                border-left: 1px solid $footer-border-color;
            }
        }
    }

    .sub-footer {
        > * {
            max-width: $max-width;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-up(xl) {
                max-width: 1310px;
            }
        }

        background: $footer-sub-bg;
        p a {
            text-decoration: none;

            span {
                color: #24B24B;
                background: linear-gradient(#24B24B, #78C159);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    .social {
        a {
            text-decoration: none;
            padding: 6px;

            &:not(:last-of-type) {
                border-radius: 50%;
                background: $footer-social-bg;
                color: $footer-social-color;
                line-height: 1;

                & + a {
                    margin-left: 10px;
                }
            }

            span {
                color: #24B24B;
                background: linear-gradient(#24B24B, #78C159);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    img.deepinsight {
        width: 120px;
    }

    p.mobile-logo {
        img {
            width: 120px;
        }

        span {
            min-width: 145px;
        }
    }

    p.sub-footer__info {
        img {
            width: 88px;
            &.webtown {
                width: 105px;
                vertical-align: -3px;
            }
        }
    }
}
