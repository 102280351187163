.main-header {
    margin-bottom: -40px;
}

.header-line,
.menu-wrapper {
    max-width: 1310px;

    @include media-breakpoint-up(lg) {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.header-line {
    padding: 35px 15px;

    color: $blue-button-color;

    .user-block {
        font-weight: 500;

        span {
            font-weight: 700;
        }

        .btn-group {
            & > button {
                i.icon-arrow-down {
                    width: 19px;
                    height: 18px;

                    background: $header-dropdown-btn-bg;
                    padding-left: 2px;
                    padding-top: 0;

                    color: $white;
                    font-size: 13px;
                }

                &:after {
                    content: none;
                }
            }

            .dropdown-menu {
                background: $header-dropdown-btn-bg;

                .dropdown-item {
                    color: $white;
                    font-weight: 500;
                    text-align: right;

                    &:hover,
                    &:focus {
                        color: $header-dropdown-hover-color;
                        background: $white;
                    }
                }

                .dropdown-divider {
                    border-color: $header-dropdown-border;
                }
            }
        }

    }

    i {
        margin-right: 10px;

        color: $header-button-icon;
        &.icon-arrow-down {
            margin-right: 0;
        }
    }
}

#main-header-navbar {
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    top: 113px;
    left: 0;
    right: 0;
    background: $header-navbar-bg;
    z-index: 10;

    @include media-breakpoint-up(lg) {
        position: static;
        background: transparent;
        padding-left: 0;
        padding-right: 0;
    }
}

.navbar {
    background: linear-gradient(to bottom, $header-navbar-shadow 0, $header-navbar-bg 15px, $header-navbar-bg 100%);
    min-height: 113px;

    @include media-breakpoint-up(lg) {
        border-radius: 5px;
        min-height: 0;
    }

    .navbar-brand {
        padding: 2.25rem 0;

        @include media-breakpoint-only(xs) {
            max-width: 65%;
        }
    }

    .navbar-toggler {
        outline: 0;
    }

    .navbar-toggler:not(.collapsed) {
        .navbar-toggler-icon {
            background-image: $navbar-dark-toggler-icon-hover-bg;
        }
    }

    .nav-item {
        .dropdown-menu {
            margin-left: -15px;
            margin-right: -15px;

            @include media-breakpoint-up(lg) {
                margin-left: 20px;
                margin-right: 0;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 40px;
            }

            background: $header-navbar-mobile-dropdown-bg;

            border: none;
            border-radius: 0;

            @include media-breakpoint-up(lg) {
                background: $header-navbar-dropdown-bg;
            }

            .dropdown-divider {
                border-color: $header-nav-item-border;

                @include media-breakpoint-up(lg) {
                    border-color: $header-navbar-dropdown-border;
                }
            }

            .dropdown-item {
                padding-top: .5em;
                padding-bottom: .5em;

                color: $header-navbar-mobile-dropdown-color;
                font-size: 1.375em;
                font-weight: 500;
                white-space: normal;

                @include media-breakpoint-up(sm) {
                    font-size: 1.75em;
                }

                @include media-breakpoint-up(lg) {
                    padding-top: 3px;
                    padding-bottom: 3px;

                    line-height: 1.2;
                    color: $header-navbar-dropdown-color;
                    font-size: 1.125em;

                    white-space: nowrap;
                }

                &.active,
                &:hover,
                &:focus {
                    background: $header-navbar-mobile-dropdown-bg;

                    color: $white;
                }
            }
        }

        &.nav-item-first {
            .dropdown-menu {
                @include media-breakpoint-up(xl) {
                    margin-left: 20px;
                }
            }
        }

        &.nav-item-first + .nav-item,
        &:nth-child(n+3) {
            border-top: 1px solid $header-nav-item-border;

            @include media-breakpoint-up(lg) {
                border-top: none;
            }

            &:before {
                @include media-breakpoint-up(lg) {
                    content: "";

                    position: absolute;
                    top: 25px;
                    bottom: 25px;

                    width: 1px;
                    height: 30px;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    background: $header-nav-item-border;
                }
            }
        }

        .nav-link {
            padding-top: .625rem;
            padding-bottom: .625rem;

            font-weight: 500;
            color: $header-navbar-color;
            font-size: 1.375em;

            @include media-breakpoint-up(sm) {
                font-size: 1.75em;
            }

            @include media-breakpoint-up(lg) {
                padding: 1.75em 20px;

                font-size: 1em;
            }

            @include media-breakpoint-up(xl) {
                padding: 1.75em 40px;
            }

            &.dropdown-toggle {
                &:after {
                    content: none;
                }
            }
        }

        &.active,
        &:hover,
        &:focus {
            .nav-link {
                position: relative;

                @include media-breakpoint-up(lg) {
                    color: $header-navbar-color;
                }

                &:after {
                    @include media-breakpoint-up(lg) {
                        content: "";

                        position: absolute;
                        bottom: 0;
                        left: 20px;
                        right: 20px;

                        display: block;
                        height: 6px;

                        background: $header-navbar-active;

                        border: none;
                        margin: 0;
                    }

                    @include media-breakpoint-up(xl) {
                        left: 40px;
                        right: 40px;
                    }
                }
            }

            &.nav-item-first {
                .nav-link {
                    &:after {
                        @include media-breakpoint-up(lg) {
                            left: 20px;
                        }
                    }
                }
            }

            &.nav-item-last {
                .nav-link {
                    &:after {
                        @include media-breakpoint-up(lg) {
                            right: 20px;
                        }
                    }
                }
            }
        }

        &.user-nav-block {
            .nav-link {
                margin-left: -15px;
                margin-right: -15px;
                padding-left: 15px;
                padding-right: 15px;

                background: $header-user-bg;

                color: $white;
                font-size: 1.375em;
                text-decoration: none;

                @include media-breakpoint-up(sm) {
                    font-size: 1.75em;
                }

                i {
                    margin-right: 15px;

                    font-size: 2rem;
                }
            }
        }

        &.nav-item-first {
            .nav-link {
                @include media-breakpoint-up(lg) {
                    padding-left: 20px;
                }
            }
        }

        &.nav-item-last {
            .nav-link {
                @include media-breakpoint-up(lg) {
                    padding-right: 20px;
                }
            }
        }
    }
}
