.form-lineheight {
  input:not([type=submit]), label {
    line-height: 35px;
  }

  .custom-control-label::before {
    top: 0.55rem;
  }

  .custom-control-label::after {
    top: 0.55rem;
  }
}

.helper-icon:before {
  content: "";
  display: block;
  background-image: url('../../images/helper_icon.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 30px;
  float: left;
  margin: 10px;
  padding: 10px;
}

.helper-icon {
  display: flex;
  align-items: center;
}

.form-control.is-invalid {
  background-image: none;
}

form {
  .icon-garbage {
    color: $form-delete-collection-color;
    cursor: pointer;
  }

  .input-group-prepend {
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.icon-info {
  color: $form-helper-icon;
  margin-left: 5px;
  margin-right: 15px;
  font-size: 1.5em;
}

.widget-fix {
  padding-top: 7px;
}

.date-picker {
  & > div[class^="col-"] {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: auto;
      flex: 0 1 auto;
    }

    > div  {
      height: auto;
      padding: 0;

      @include media-breakpoint-up(sm) {
        height: 40px;
      }

      select {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
        &:nth-last-child(2),
        &:nth-last-child(3) {
          @include media-breakpoint-up(sm) {
            margin-right: 10px;
            margin-bottom: 0;
          }
        }

        &:nth-last-child(2),
        &:last-child {
          @include media-breakpoint-up(sm) {
            margin-left: 10px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
