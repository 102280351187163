.card-deck {
  .card {
    border: 0;

    @include media-breakpoint-up(sm) {
      margin-bottom: 15px;
      flex: 0 1 auto;
    }

    @include media-breakpoint-up(md) {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
}
